"use strict";

var app = {
  mobileMenu: function mobileMenu() {
    var mobileBtn = $("#mobile-burger-btn");
    var pageBody = $("#page-wrap-inner");

    if (mobileBtn.length > 0) {
      // Load Menu
      mobileBtn.click(function () {
        if (mobileBtn.hasClass("menu-open") == true) {
          pageBody.removeClass("push");
          mobileBtn.removeClass("menu-open");
        } else {
          pageBody.addClass("push");
          mobileBtn.addClass("menu-open");
        }
      });
    }
  },
  validateEmail: function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  testimonials: function testimonials() {
    $("#testimonials").slick({
      dots: true,
      appendDots: "#paging",
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 2000,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [{
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    });
  },
  alertForm: function alertForm() {
    $('[data-fancybox="alert"]').fancybox({
      afterShow: function afterShow(instance, current) {
        var submit_alert_btn = $("#submit_alert_btn");
        var form = $("#alert-frm form");

        // AJAX FILTER
        var parent_sel = $("#sector_sel");
        parent_sel.on("change", function () {
          var selected = $(this).val();
          selected = selected.toLowerCase().replace(/ /g, "-").replace(/[^\w-]+/g, "");
          console.log("SELECTED == ", selected);
          $.ajax({
            url: "!/TaxonomiesFilter/listings?parent=" + selected
          }).done(function (response) {
            console.log("RESPONSE = ", response);

            var html = '<option value="">Job Type:</option>';
            $.each(response, function (index, value) {
              html += '<option value="' + value.title + '"> - ' + value.title + "</option>";
            });
            $("#job_type_sel").html(html);
          });
        });

        submit_alert_btn.on("click", function (e) {
          e.preventDefault();

          var formData = $(form).serialize();
          var valid = true;

          var name_txt = form.find("#name_txt");
          var email_txt = form.find("#email_txt");

          name_txt.removeClass("required");
          email_txt.removeClass("required");

          if (name_txt.val() == "") {
            name_txt.addClass("required");
            valid = false;
          }
          if (app.validateEmail(email_txt.val()) == false) {
            email_txt.addClass("required");
            valid = false;
          }

          if (valid == true) {
            if ($("#validation-msg").length > 0) {
              $("#validation-msg").remove();
            }

            $.ajax({
              type: "POST",
              url: $(form).attr("action"),
              data: formData
            }).done(function (response) {
              if (response.success == true) {
                form.hide();
                $('<div id="validation-msg" class="validation-msg">Thank you for submitting your alert</div>').insertBefore(form);
              } else {
                $('<div id="validation-msg" class="validation-msg error">We had an issue submitting your data.  Please try again.</div>').insertBefore(form);
              }
            }).fail(function (data) {
              $('<div id="validation-msg" class="validation-msg error">We had an issue submitting your data.  Please try again.</div>').insertBefore(form);
            });
          }
        });
      }
    });
  },

  submitcvForm: function submitcvForm() {
    $('[data-fancybox="submitcv"]').fancybox({
      afterShow: function afterShow(instance, current) {
        var submit_cv_btn = $("#submit_cv_btn");
        var form = $("#submitcv-frm form");
        submit_cv_btn.on("click", function (e) {});
      }
    });
  },

  applyforjobForm: function applyforjobForm() {
    var openbtn = $("#applybtn");
    var job_title = openbtn.attr("data-jobtitle");
    var job_staff = openbtn.attr("data-jobstaff");
    var job_url = window.location.href;

    $('[data-fancybox="applyforjob"]').fancybox({
      afterShow: function afterShow(instance, current) {
        var submit_apply_btn = $("#submit_applyforjob_btn");
        var form = $("#applyforjob-frm form");
        $("#jobtitle").val(job_title);
        $("#jobstaff").val(job_staff);
        $("#joburl").val(job_url);
        submit_apply_btn.on("click", function (e) {});
      }
    });
  },

  aboutservices: function aboutservices() {
    $("#aboutservices").slick({
      autoplay: true,
      autoplaySpeed: 1000,
      fade: true
    });
  },

  expertiseCarousel: function expertiseCarousel() {
    $(".ImageExpertise").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      arrows: true,
      autoplay: false,
      nextArrow: $(".next"),
      prevArrow: $(".prev"),
      fade: true,
      asNavFor: ".ImageText"
    });

    $(".ImageText").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      asNavFor: ".ImageExpertise",
      dots: false,
      arrows: false,
      autoplay: false,
      fade: true,
      focusOnSelect: true
    });
  },

  filterDropdown: function filterDropdown(e) {
    var select = $(e)[0];
    var getType = select.dataset.type;
    var getValue = select.dataset.value;

    var sectors_sel = $("#sectors_sel").val();
    var job_types_sel = $("#job_types").val();
    var locations_sel = $("#locations_sel").val();

    // FILTER BASED ON SECTORS
    if (getType == "sectors") {
      // Look up other params
      var params = "sector=" + getValue;

      if (job_types_sel != "") {
        params += "&types=" + job_types_sel;
      }
      if (locations_sel != "") {
        params += "&locations=" + locations_sel;
      }

      // Filter other dropdowns
      var filter_types = ["locations", "job_types"];
      $.ajax({
        url: "/!/JobsFilter/listings?" + params
      }).done(function (response) {
        $.each(filter_types, function (typeKey, typeRow) {});
      });
    }

    // FILTER BASED ON JOB_TYPES
    if (getType == "job_types") {
      var params = "job_types=" + getValue;

      if (sectors_sel != "") {
        params += "&sector=" + job_types_sel;
      }
      if (locations_sel != "") {
        params += "&locations=" + locations_sel;
      }
      // Filter other dropdowns
      var filter_types = ["sectors", "locations"];
    }

    //
    if (getType == "locations") {
      var params = "locations=" + getValue;

      if (sectors_sel != "") {
        params += "&sector=" + job_types_sel;
      }
      if (job_types_sel != "") {
        params += "&types=" + job_types_sel;
      }

      // Filter other dropdowns
      var filter_types = ["sectors", "job_types"];
    }

    app.ajaxFilters(params, filter_types);
  },


  ajaxFilters: function ajaxFilters(params, filter_types) {
    $.each(filter_types, function (typeKey, typeRow) {
      $.ajax({
        url: "!/JobsFilter/" + typeRow + "?" + params
      }).done(function (response) {
        var parent = $('.select-items[data-type="' + typeRow + '"]').find("div");

        parent.each(function (key, row) {
          var row = $(row);
          if (row.hasClass("hidden")) {
            row.removeClass("hidden");
          }
          var text = row.attr("data-text");
          var value = row.attr("data-value");
          if (value != "") {
            var found = false;
            $.each(response, function (response_key, response_value) {
              if (response_value.slug == value) {
                row.html(text + " (" + response_value.count + ")");
                found = true;
              }
            });
            if (found == false) {
              row.addClass("hidden");
            }
          }
        });
      });
    });
  },

  init: function init() {
    app.mobileMenu();

    if ($("#aboutservices").length > 0) {
      app.aboutservices();
    }

    if ($(".ImageExpertise").length > 0) {
      app.expertiseCarousel();
    }

    if ($("#testimonials").length > 0) {
      app.testimonials();
    }

    if ($('[data-fancybox="alert"]').length > 0) {
      app.alertForm();
    }

    if ($('[data-fancybox="submitcv"]').length > 0) {
      app.submitcvForm();
    }

    if ($('[data-fancybox="applyforjob"]').length > 0) {
      app.applyforjobForm();
    }
  }
};

$(document).ready(function () {
  app.init();
});